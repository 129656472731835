import { createStore } from 'vuex';
//import api from '../lib/api';
//import Usuarios from './modules/usuarios';

import { Empresa } from './modules/empresa';

let user = null;
let empresa = null;


try {
    user = JSON.parse(localStorage.getItem('user'));
} catch (err) {
    user = null;
}

try {
    empresa = JSON.parse(localStorage.getItem('empresa'));
} catch (err) {
    empresa = null;
}



export const store = createStore({
    state() {
        return {
            user,
            usuarios: [],
            //empresa,
            onLine: !!navigator.onLine
        }
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        /*
        setEmpresa(state, empresa) {
            state.empresa = empresa;
        },
        */
        setOnLine(state, online) {
            state.onLine = online;
        },
        setUsuarios(state, usuarios) {
            state.usuarios = usuarios;
        }
    },
    actions: {
        
        setAccessToken({ commit, dispatch }, access_token) {
            if (null === access_token) {
                localStorage.removeItem('access_token');
                return;
            }            
            localStorage.setItem('access_token', access_token);
            dispatch('load');
        },
        setUser({ commit, dispatch }, user) {
            commit('setUser', user);
            if (null === user) {
                localStorage.removeItem('user');
                return;
            }
            localStorage.setItem('user', JSON.stringify(user));
            dispatch('sync');
        },
        /*
        setEmpresa({ commit }, empresa) {
            commit('setEmpresa', empresa);
            if (null === empresa) {
                localStorage.removeItem('empresa');
                return;
            }
            localStorage.setItem('empresa', JSON.stringify(empresa));
        },*/
        signOut({ dispatch }) {
            dispatch('setAccessToken', null);
            dispatch('setUser', null);
            dispatch('setEmpresa', null);
        },
        sync({ dispatch }) {
            //dispatch('Usuarios/sync');
        },
        load({ dispatch }) {
            //dispatch('Usuarios/load');
            window.setTimeout(() => {

                
                dispatch('Empresa/load');
            })
        }
    },
    getters: {
        usuario(state) {
            return state.user;
        }
    },
    modules: {
        //Usuarios,
        
        Empresa
    }
});

window.addEventListener('offline', () => {
    store.commit('setOnLine', false);
});
window.addEventListener('online', () => {
    store.commit('setOnLine', true);
  //  store.dispatch('sync');
});
store.dispatch('load');
if (navigator.onLine) {
    //store.dispatch('sync');
}

export default store;
