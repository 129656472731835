import { createRouter, createWebHistory } from 'vue-router';
//import routes from '~pages';
import routes from 'voie-pages'

import $store from './store';

export const router = createRouter({
    history: createWebHistory(import.meta.env.VITE_APPLICATION_PATH),
    routes,
});

router.beforeEach(async (to, from) => {
    
    let isAuthenticated = false;
    try {
        let access_token = localStorage.getItem('access_token');
        let user = JSON.parse(localStorage.getItem('user'));
        if (access_token && user) {
            isAuthenticated = true;
        }
    } catch (err) {
        console.error(err);
    }

    if (!/^\/login/.exec(to.fullPath) && !isAuthenticated) {
        return '/login';
    }
    
    if (/^\/login/.exec(to.fullPath) && isAuthenticated) {
        return '/';
    }

    if (isAuthenticated) {
        let user = $store.state.user;
        if (user.tipo == 'Auxiliar de Estoque') {
            if (!/^\/(contagens)/.exec(to.fullPath)) {
                return '/contagens';
            }
        }
        if (user.tipo == 'Auditor') {
            if (!/^\/(auditoria|monitor)/.exec(to.fullPath)) {
                return '/auditoria';
            }
        }
        if (user.tipo == 'Administrativo') {
            if (!/^\/(contagens|enderecos|monitor)/.exec(to.fullPath)) {
                return '/contagens';
            }
        }
    }
    
    //next();
});

export default router;