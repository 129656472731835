export default [{path: '/auditoria',
props: true,
component: () => import('/pages/auditoria.vue'),
children: [
      {name: 'auditoria',
path: '',
props: true,
component: () => import('/pages/auditoria/index.vue')},

    ]},
{path: '/configuracoes',
props: true,
component: () => import('/pages/configuracoes.vue'),
children: [
      {path: 'arquivos',
props: true,
component: () => import('/pages/configuracoes/arquivos.vue'),
children: [
      {name: 'configuracoes-arquivos',
path: '',
props: true,
component: () => import('/pages/configuracoes/arquivos/index.vue')},{name: 'configuracoes-arquivos-novo',
path: 'novo',
props: true,
component: () => import('/pages/configuracoes/arquivos/novo.vue')},

    ]},{name: 'configuracoes',
path: '',
props: true,
component: () => import('/pages/configuracoes/index.vue')},{name: 'configuracoes-config-divergencia-id',
path: 'config-divergencia/:id',
props: true,
component: () => import('/pages/configuracoes/config-divergencia/[id].vue')},{name: 'configuracoes-config-divergencia-nova',
path: 'config-divergencia/nova',
props: true,
component: () => import('/pages/configuracoes/config-divergencia/nova.vue')},

    ]},
{path: '/contagens',
props: true,
component: () => import('/pages/contagens.vue'),
children: [
      {name: 'contagens',
path: '',
props: true,
component: () => import('/pages/contagens/index.vue')},{name: 'contagens-uuid',
path: ':uuid',
props: true,
component: () => import('/pages/contagens/[uuid]/index.vue')},

    ]},
{path: '/divergencias',
props: true,
component: () => import('/pages/divergencias.vue'),
children: [
      {name: 'divergencias',
path: '',
props: true,
component: () => import('/pages/divergencias/index.vue')},

    ]},
{path: '/enderecos',
props: true,
component: () => import('/pages/enderecos.vue'),
children: [
      {name: 'enderecos',
path: '',
props: true,
component: () => import('/pages/enderecos/index.vue')},

    ]},
{path: '/fechamentos',
props: true,
component: () => import('/pages/fechamentos.vue'),
children: [
      {name: 'fechamentos',
path: '',
props: true,
component: () => import('/pages/fechamentos/index.vue')},

    ]},
{name: 'index',
path: '/',
props: true,
component: () => import('/pages/index.vue')},
{path: '/login',
props: true,
component: () => import('/pages/login.vue'),
children: [
      {name: 'login',
path: '',
props: true,
component: () => import('/pages/login/index.vue')},{name: 'login-recover',
path: 'recover',
props: true,
component: () => import('/pages/login/recover.vue')},

    ]},
{path: '/lojas',
props: true,
component: () => import('/pages/lojas.vue'),
children: [
      {name: 'lojas',
path: '',
props: true,
component: () => import('/pages/lojas/index.vue')},{name: 'lojas-novo',
path: 'novo',
props: true,
component: () => import('/pages/lojas/novo.js')},{name: 'lojas-editar-uuid',
path: 'editar/:uuid',
props: true,
component: () => import('/pages/lojas/editar/[uuid].vue')},

    ]},
{path: '/lotes',
props: true,
component: () => import('/pages/lotes.vue'),
children: [
      {name: 'lotes',
path: '',
props: true,
component: () => import('/pages/lotes/index.vue')},

    ]},
{path: '/monitor',
props: true,
component: () => import('/pages/monitor.vue'),
children: [
      {name: 'monitor',
path: '',
props: true,
component: () => import('/pages/monitor/index.vue')},{name: 'monitor-user-id',
path: 'user/:id',
props: true,
component: () => import('/pages/monitor/user/[id].vue')},

    ]},
{path: '/produtos',
props: true,
component: () => import('/pages/produtos.vue'),
children: [
      {name: 'produtos',
path: '',
props: true,
component: () => import('/pages/produtos/index.vue')},{name: 'produtos-novo',
path: 'novo',
props: true,
component: () => import('/pages/produtos/novo.js')},{name: 'produtos-editar-uuid',
path: 'editar/:uuid',
props: true,
component: () => import('/pages/produtos/editar/[uuid].vue')},

    ]},
{path: '/saldos',
props: true,
component: () => import('/pages/saldos.vue'),
children: [
      {name: 'saldos',
path: '',
props: true,
component: () => import('/pages/saldos/index.vue')},

    ]},
{path: '/usuarios',
props: true,
component: () => import('/pages/usuarios.vue'),
children: [
      {name: 'usuarios',
path: '',
props: true,
component: () => import('/pages/usuarios/index.vue')},{name: 'usuarios-novo',
path: 'novo',
props: true,
component: () => import('/pages/usuarios/novo.js')},{name: 'usuarios-editar-uuid',
path: 'editar/:uuid',
props: true,
component: () => import('/pages/usuarios/editar/[uuid].vue')},

    ]}];